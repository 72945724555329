<template>
  <div
    id="banner-image-header"
    class="lenna-chat-header lenna-card-header lenna-text-white lenna-rounded-border-top"
  >
    <div class="lenna-row lenna-d-flex lenna-align-items-center">
      <div class="lenna-col-2">
        <!-- <img :src="style.header_logo" class="lenna-lenna-logo" /> -->
      </div>
      <div
        class="lenna-col-8 lenna-align-items-center lenna-d-flex"
        style="height: 40px"
      ></div>
      <div class="lenna-col-2">
        <img
          src="@/assets/images/icons/close-icon-circle.svg"
          class="lenna-flex-1 lenna-pointer lenna-close-chat"
          @click="closeWindow"
          style="heigh: 24px; width: 24px; margin-left: -8px;"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  methods: {
    closeWindow() {
      this.$store.dispatch("window/updateIsOpen", false);
    }
  },
  computed: {
    ...mapGetters({
      style: "style/getStyle"
    })
  }
};
</script>
<style lang="scss" scoped>
.lenna-chat-header {
  // background: #0086d2;
  img.lenna-lenna-logo {
    width: 35px !important;
  }
  padding: 20px;
  // background: url("~@/assets/images/header.jpeg");
  background-size: cover !important;
  // box-shadow: 0 25px 30px -9px rgba(0, 0, 0, 0.2);
  // position: sticky;
}

.lenna-close-chat {
  position: relative;
  bottom: 1.2em;
  left: 1.5em;
}
#banner-image-header {
  background: url("~@/assets/images/banner-asset.jpg");
}
</style>
