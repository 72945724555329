<template>
  <div class="lenna-footer-container lenna-rounded-border">
    <div
      class="lenna-chat-footer lenna-card-footer lenna-rounded-border-bottom lenna-bg-white lenna-p-1"
    >
      <div class="row">
        <div v-if="showUpload" class="col-md-12 upload-container lenna-pb-3">
          <vue-dropzone
            :options="dropzone"
            ref="myVueDropzone"
            id="dropzone dropzoneCustom"
            :useCustomSlot="true"
            :duplicateCheck="true"
            @vdropzone-removed-file="vremoved"
            @vdropzone-sending="vadded"
            @vdropzone-success="vsuccess"
            @vdropzone-error="dropzoneError"
          >
            <div class="dropzone__content dropzone-custom-content pointer">
              <img
                src="@/assets/images/upload-icon.png"
                style="width:50px"
                class="lenna-text-center"
              />
              <br />
              <b class="lenna-text-primary d-block"
                >Drag and drop to upload content</b
              >
              <br />
              <small class="text-muted">
                or click to select a file from your computer
              </small>
              <br />
              <small class="text-info">
                maximum file is 5000 KB / Kilobyte
              </small>
            </div>
          </vue-dropzone>
          <div class="d-flex lenna-pt-2 upload__btn">
            <button
              class="lenna-btn lenna-btn-primary btn-send"
              @click="sendFile"
            >
              <i class="fa fa-paper-plane" aria-hidden="true"></i>
            </button>
            <button
              class="lenna-btn lenna-btn-primary btn-send lenna-ml-2"
              @click="closeUploadFile"
            >
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
      <!-- 390 -->
      <VEmojiPicker
        v-show="showEmoticon"
        class="emoji-picker"
        labelSearch="Search"
        lang="pt-BR"
        @select="handleSelectEmoji"
      />
      <div
        class="lenna-row lenna-no-gutters lenna-d-flex lenna-align-items-center"
      >
        <!-- <div class="lenna-col-1">
          <img src="@/assets/images/icons/ic_smiley.png" class="lenna-pointer" />
        </div> -->
        <div class="lenna-col lenna-pl-2 lenna-pr-1">
          <textarea
            type="text"
            class="lenna-form-control lenna-text-input lenna-text-chat"
            style="min-height: unset"
            placeholder="Ketik disini.."
            lenna-rows="1"
            v-model="message.text"
            :disabled="disabled"
            @keypress.enter.prevent="sendText"
            @focus="hovered = true"
            @blur="hovered = false"
            @input="showSpeech"
          ></textarea>
          <div class="lenna-chat-action">
            <img
              src="@/assets/images/icons/ic_clip.png"
              class="lenna-pointer"
              @click="openUploadFile"
            />
            <img
              v-if="appId[0] !== 390"
              src="@/assets/images/icons/ic_smiley.png"
              class="lenna-pointer emoji-button"
              @click="handleShowEmoticon"
            />
            <img
              v-else
              @click="sendLiveChat"
              width="25"
              src="https://app.lenna.ai/page/liveagenticon.png"
              alt="livechat-button"
              class="lenna-pointer"
            />
            <!-- <img
              src="@/assets/images/icons/ic_call.png"
              class="lenna-pointer"
            />
            <img
              src="@/assets/images/icons/ic_vcall.png"
              class="lenna-pointer"
            /> -->
          </div>
          <!-- <coolpicker :emojiData="emojiDataAll" :emojiGroups="emojiGroups" :skinsSelection="true"></coolpicker> -->
        </div>
        <!-- <div class="lenna-col-1 lenna-text-center">
          <img
            src="@/assets/images/icons/ic_clip.png"
            class="lenna-pointer"
            @click="openUploadFile"
          />
        </div> -->
        <!-- <div class="lenna-col-1 lenna-text-center">
          <img
            v-if="appId[0] !== 390"
            src="@/assets/images/icons/ic_smiley.png"
            class="lenna-pointer emoji-button"
            @click="handleShowEmoticon"
          />
          <img
            v-else
            @click="sendLiveChat"
            width="23"
            src="https://app.lenna.ai/page/liveagenticon.png"
            alt="livechat-button"
            class="lenna-pointer"
          />
        </div> -->
        <div class="lenna-col-1 lenna-text-center" v-show="isSpeech">
          <img
            :src="
              require('@/assets/images/icons/ic_mic.png')
            "
            class="lenna-pointer lenna-icon-mic"
            @click="sendText"
            @mouseover="hovered = true"
            @mouseleave="hovered = false"
          />
        </div>
        <div class="lenna-col-1 lenna-text-center" v-show="isTyping">
          <img
            :src="
              require('@/assets/images/icons/btn_send_chat.png')
            "
            class="lenna-pointer lenna-icon-send"
            @click="sendText"
            @mouseover="hovered = true"
            @mouseleave="hovered = false"
          />
          <!-- <IconBase
            class="icon-send"
            width="20"
            height="20"
            icon-color="#949494"
          >
            <icon-send />
          </IconBase> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import * as validation from "@/helpers/validator";
import { VEmojiPicker } from "v-emoji-picker";
import VueDropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import moment from "moment";
import { isEmpty, divide, remove, cloneDeep } from "lodash";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      showEmoticon: false,
      showUpload: false,
      uploadedFiles: [],
      message: {
        text: "hai",
        image: null
      },
      isResetDropzone: false,
      dropzone: {
        url: `${process.env.VUE_APP_OMNICHANNEL_URL}/${this.$store.getters.appId.hashed}/upload-file-webchat`,
        thumbnailWidth: 350,
        // resizeWidth: 200,
        thumbnailHeight: 170,
        addRemoveLinks: true,
        thumbnailMethod: "contain",
        maxFilesize: 5,
        clickable: true,
        renameFile: function(file) {
          const newName = `${moment().format("x")}-${file.name}`;
          return newName;
        }
      },
      hovered: false,
      omniAPIUrl: process.env.VUE_APP_OMNICHANNEL_URL,
      errorDropzone: false,
      isTyping: false,
      isSpeech: true
    };
  },
  components: {
    VEmojiPicker,
    VueDropzone: VueDropzone
  },
  async mounted() {
    await this.$store.dispatch("chat/updateQuickButton", []);
    await this.messageAfterRegister();
    this.message.text = "";
    // this.$store.dispatch("chat/newLoad");
  },
  computed: {
    ...mapGetters({
      disabled: "other/disableInput",
      appId: "decodedAppId"
    })
  },
  methods: {
    showSpeech() {
      if (this.message.text != '') {
        this.isTyping = true;
        this.isSpeech = false;
      } else {
        this.isTyping = false;
        this.isSpeech = true;
      }
    },
    sendFile() {
      const max = this.dropzone.maxFilesize;
      remove(this.uploadedFiles, function(o) {
        return o.fileSize >= max;
      });
      if (this.uploadedFiles.length !== 0 && !this.errorDropzone) {
        for (const value of this.uploadedFiles) {
          const message = {
            fileName: value.fileName,
            type: value.fileType,
            fileUrl: value.fileUrl
          };
          this.sendImage(message);
        }
      }
      if (this.errorDropzone) {
        this.$notify({
          group: "error",
          type: "error",
          title: "Error",
          text: "Please upload some file or file to large!"
        });
        console.log("Please upload some file or file to large!");
        return;
      }
      this.isResetDropzone = true;
      this.errorDropzone = false;
      this.$refs.myVueDropzone.removeAllFiles(true);
      this.clearDropzone();
    },
    clearDropzone() {
      this.uploadedFiles = [];
      this.isResetDropzone = this.errorDropzone = this.showUpload = false;
    },
    openUploadFile() {
      this.showUpload = true;
    },
    closeUploadFile() {
      this.clearDropzone();
      this.$refs.myVueDropzone.removeAllFiles(true);
    },
    async vremoved(file, error, xhr) {
      this.errorDropzone = false;
      if (!this.isResetDropzone) {
        await this.$axios.post(
          `${this.omniAPIUrl}/${this.$store.getters.appId.hashed}/delete-file-webchat`,
          {
            fileToDelete: file.upload.filename,
            channel: "webchat"
          }
        );
        remove(this.uploadedFiles, function(n) {
          return n.fileName == file.upload.filename;
        });
      }
      console.log("error vremoved", error);
      console.log("xhr vremoved", xhr);
    },
    vsuccess(file, response) {
      console.log("type file", file.type.split("/")[0]);
      console.log("file", file);
      // console.log("response", response.data)
      var type = "";
      const fileType = file.type.split("/")[0];
      if (fileType == "image") {
        type = "image";
      } else {
        type = "file";
      }
      if (response.success) {
        let fileToPush = {
          fileName: response.data.fileName,
          fileUrl: response.data.fileUrl,
          fileType: type,
          fileSize: divide(file.size, 1000000)
        };
        this.uploadedFiles.push(fileToPush);
      }
    },
    handleSelectEmoji(emoji) {
      this.message.text += `${emoji.data} `;
    },
    handleShowEmoticon() {
      if (!this.disabled) {
        this.showEmoticon = !this.showEmoticon;
      }
    },
    vadded(file, xhr, formData) {
      console.log("vadded");
      formData.append("channel", "webchat");
    },
    sendLiveChat() {
      this.message.text = "live agent";
      this.sendText();
    },
    async sendText() {
      // this.$store.dispatch("chat/updateQuickButton", []);
      if (this.message.text == "" || this.message.text == null) {
        return;
      }
      if (isEmpty(this.message.text)) {
        return;
      }
      this.showEmoticon = false;
      // this.$send.play();
      this.$store.dispatch("other/enableScroll", true);
      this.$store.dispatch("other/triggerScroll", true);
      let textMessage = {
        type: "text",
        text: cloneDeep(this.message.text)
      };
      this.message.text = "";
      await this.$store.dispatch("chat/sendMessage", textMessage);
      await this.$store.dispatch("other/triggerInfinityScroll", true);
      this.$store.dispatch("other/triggerScroll", false);
    },
    async sendImage(data) {
      console.log("send image", data);
      if (this.uploadedFiles.length == 0) {
        return;
      }
      // this.$send.play();
      this.$store.dispatch("other/enableScroll", true);
      this.$store.dispatch("other/triggerScroll", true);
      await this.$store.dispatch("chat/sendMessage", data);
      await this.$store.dispatch("other/triggerInfinityScroll", true);
      this.$store.dispatch("other/triggerScroll", false);
    },
    messageAfterRegister() {
      const isFirst = this.$store.getters["chat/isFirst"];
      if (isFirst) {
        this.sendText();
        this.$store.dispatch("chat/isFirst", false);
        this.$store.dispatch("other/disableInput", false);
      }
    },
    dropzoneError(file, message, xhr) {
      this.errorDropzone = true;
      var elements = document.querySelectorAll(".dz-error-message span");
      var lastElement = elements[elements.length - 1];
      if (xhr) {
        console.log("xhr", xhr);
        let responseError = JSON.parse(xhr.response);
        let msg =
          responseError.error == null
            ? responseError.message
            : responseError.error;
        lastElement.textContent = msg;
      } else {
        console.log("message", message);
        lastElement.textContent = message;
      }
    },
  }
};
</script>
<style lang="scss" scoped>
.lenna-chat-action {
  position: absolute;
  top: 0;
  right: 1.2em;
  top: 6px;
  img {
    width: 20px;
    height: 20px;
    margin: 0 5px;
  }
}

.lenna-text-chat {
  padding: .375rem 9em .375rem 1.3em;
  background: #F6F7FB !important;
  border-radius: 8px;
}

.lenna-text-chat:focus {
  background: #F6F7FB !important;
}

.lenna-icon-mic {
  width: 24px;
  height: 24px;
}

.lenna-icon-send {
  width: 16px;
  height: 16px;
}

.upload__btn {
  align-items: center;
}
.emoji-picker {
  position: absolute;
  bottom: 10%;
  // height: 50%;
}
.upload-container {
  display: flex;
  width: 100%;
  padding: 0px 10px 0px 10px;
  position: absolute;
  z-index: 1;
  align-items: center;
  left: 0%;
  flex-direction: column;
  bottom: 0% !important;
  background-color: #f4f4f4;
}
.lenna-text-input {
  border: none !important;
  resize: none;
  background-color: #f4f4f4;
  &:focus {
    box-shadow: none;
  }
}

.btn-send {
  padding: 0px;
  width: 50px;
  height: 50px;
  border-radius: 100% !important;
}
.lenna-footer-container {
  background: #f4f4f4;

  & .lenna-row {
    padding: 5px 0;
  }
}
.dropzone {
  border: 2px dashed #673ab75e !important;
}
</style>
<style lang="scss">
.vue-dropzone {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 150px;
  overflow: scroll;
  background-color: #f4f4f4;
}
// .dropzone .dz-preview {
//   width: 10px !important;
// }
#lenna-webchat-bnil .vue-dropzone > .dz-preview a.dz-remove {
  color: #ffffff !important;
}
</style>
