var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lenna-message lenna-d-flex lenna-others",class:{ 'lenna-others-block': !_vm.isSelf },staticStyle:{"padding-left":"0 !important","padding-right":"0 !important"}},[_c('div',{class:{
      'lenna-d-flex lenna-custom-header lenna-custom-carousel': !_vm.isSelf
    }},[_c('message-avatar',{attrs:{"isSelf":_vm.isSelf,"message":_vm.message}}),_c('message-head',{attrs:{"isSelf":_vm.isSelf,"message":_vm.message}})],1),_c('div',{staticClass:"lenna-message-container"},[_c('div',{staticClass:"lenna-carousel-wrapper"},[_c('div',{staticClass:"lenna-message-content lenna-d-flex custom-carousel-content custom-scrollbar-visible"},_vm._l((_vm.content.columns),function(carousel,key){return _c('div',{key:key,staticClass:"lenna-card lenna-carousel-container",style:({
            'background-color': _vm.style.bubble_other
          })},[_c('div',{staticClass:"lenna-container-carousel-img text-center"},[_c('img',{staticClass:"lenna-card-img-top lenna-mx-auto lenna-carousel-img lenna-img-fluid",attrs:{"src":carousel.thumbnailImageUrl !== `${_vm.botStudioUrl}/storage/`
                  ? carousel.thumbnailImageUrl
                    ? carousel.thumbnailImageUrl
                    : require('@/assets/images/image_not_found.jpg')
                  : require('@/assets/images/image_not_found.jpg')}})]),_c('div',{staticClass:"lenna-card-body lenna-carousel-card-body"},[_c('p',{staticClass:"lenna-card-title",style:({
                color: _vm.style.text_other
              })},[_vm._v("\n              "+_vm._s(carousel.title)+"\n            ")]),_c('p',{staticClass:"lenna-card-text lenna-roboto-regular-font",style:({
                color: '#969e9e'
              })},[_vm._v("\n              "+_vm._s(carousel.text)+"\n            ")])]),_c('ul',{staticClass:"lenna-list-group lenna-list-group-flush lenna-text-center lenna-text-color"},_vm._l((carousel.actions),function(item,index){return _c('li',{key:index,staticClass:"lenna-list-group-item lenna-carousel-list-group-item",staticStyle:{"cursor":"pointer"},style:({
                'background-color': _vm.style.bubble_other
              }),on:{"click":function($event){return _vm.actionsClickHandler(item)}}},[_c('p',{staticClass:"lenna-carousel-action-text"},[_vm._v(_vm._s(item.label))])])}),0)])}),0)]),_c('message-footer',{attrs:{"isSelf":_vm.isSelf,"message":_vm.message}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }