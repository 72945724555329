<template>
  <div
    class="lenna-message"
    :class="{
      'lenna-self': isSelf,
      'lenna-others': !isSelf,
      'lenna-others-block': !isSelf,
      'lenna-d-flex': !checkIsObject(content.text)
    }"
    :style="{ display: checkIsObject(content.text) ? 'none' : '' }"
  >
    <div
      :class="{
        'lenna-d-flex lenna-custom-header': !isSelf
      }"
    >
      <message-avatar :isSelf="isSelf" :message="message" />
      <message-head :isSelf="isSelf" :message="message" />
    </div>
    <div class="lenna-message-container">
      <!-- <div class="lenna-message-content lenna-box-shadow"> -->
      <div
        class="lenna-message-content"
        :class="{
          handleClassAnimate
        }"
        :id="handleId"
        :style="{
          'background-color': isSelf ? 'transparent' : '#ff7429',
          border: isSelf ? '2px solid #cf8963' : 'none',
          'border-radius': isSelf
            ? '24px 24px 0px 24px !important'
            : '24px 24px 24px 0 !important'
        }"
      >
        <div
          class="lenna-text-content"
          :style="{
            color: isSelf ? 'black' : 'white'
          }"
        >
          <span style="white-space: pre-line;word-break: break-word;">
            {{ this.content.text }}
          </span>
        </div>
      </div>
      <message-footer :isSelf="isSelf" :message="message" />
    </div>
  </div>
</template>
<script>
import messageTypeMixin from "../mixins/messageTypeMixin";
import { isEqualWith } from "lodash";
import { mapGetters } from "vuex";
export default {
  mixins: [messageTypeMixin],
  props: ["id", "type", "total"],
  computed: {
    ...mapGetters({
      style: "style/getStyle",
      isMasking: "chat/maskingInput"
    }),
    list_contents() {
      return this.extractMessage(this.content.text);
    },
    handleId() {
      if (this.type == "user") {
        return "lenna-self-animated-" + this.id;
      } else {
        return "lenna-other-animated-" + this.id;
      }
    },
    handleClassAnimate() {
      if (this.type == "user") {
        if (this.id + 1 == this.total) {
          return "fade-in-right";
        }
      } else {
        if (this.id + 1 == this.total) {
          return "fade-in-left";
        }
      }
    }
  },
  methods: {
    handleClickLink(link) {
      window.open(`http://${link}`, "__blank");
    },
    extractMessage(message) {
      let expression = /(https?:\/\/)?[\w\-~]+(\.[\w\-~]+)+(\/[\w\-~@:%]*)*(#[\w\-]*)?(\?[^\s]*)?/gi;

      let regex = new RegExp(expression);
      let match = "";
      let splitText = [];
      let startIndex = 0;
      while ((match = regex.exec(message)) != null) {
        splitText.push({
          text: message.substr(startIndex, match.index - startIndex),
          type: "text"
        });

        let cleanedLink = message.substr(match.index, match[0].length);
        cleanedLink = cleanedLink.replace(/^https?:\/\//, "");
        splitText.push({ text: cleanedLink, type: "link" });

        startIndex = match.index + match[0].length;
      }
      if (startIndex < message.length)
        splitText.push({ text: message.substr(startIndex), type: "text" });
      return splitText;
    },
    checkIsObject(val) {
      if (isEqualWith(val, "{}")) {
        return true;
      }
      let regexObject = /.*\{".*\"}.*/;
      let result = regexObject.test(val);
      return result;
    }
  }
};
</script>
<style lang="scss" scoped>
/**
 * ----------------------------------------
 * animation bounce-in-left
 * ----------------------------------------
 */
@-webkit-keyframes bounce-in-left {
  0% {
    -webkit-transform: translateX(-600px);
    transform: translateX(-600px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateX(-68px);
    transform: translateX(-68px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateX(-28px);
    transform: translateX(-28px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes bounce-in-left {
  0% {
    -webkit-transform: translateX(-600px);
    transform: translateX(-600px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateX(-68px);
    transform: translateX(-68px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateX(-28px);
    transform: translateX(-28px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

/**
 * ----------------------------------------
 * animation bounce-in-right
 * ----------------------------------------
 */
@-webkit-keyframes bounce-in-right {
  0% {
    -webkit-transform: translateX(600px);
    transform: translateX(600px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateX(68px);
    transform: translateX(68px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateX(32px);
    transform: translateX(32px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes bounce-in-right {
  0% {
    -webkit-transform: translateX(600px);
    transform: translateX(600px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateX(68px);
    transform: translateX(68px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateX(32px);
    transform: translateX(32px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
.bounce-in-right {
  -webkit-animation: bounce-in-right 1.1s both;
  animation: bounce-in-right 1.1s both;
}

.bounce-in-left {
  -webkit-animation: bounce-in-left 1.1s both;
  animation: bounce-in-left 1.1s both;
}

.lenna-message {
  padding: 5px 10px;
  .lenna-text-content {
    font-family: "roboto-light", sans-serif !important;
    word-break: break-word;
    line-height: 16px;
    // white-space: pre-line;
    // white-space: normal;
    margin-top: -15px;
  }
}
.lenna-message-content {
  word-break: break-all;
  padding: 8px 15px;
  // color: $dark;
  // background: $light-gray;
  border-radius: 4px 4px 4px 0;
}
.lenna-message.lenna-self {
  //   max-width: 80%;
  flex-direction: row-reverse;

  .lenna-message-content {
    text-align: left;
    // color: white;
    // background: $dark-blue;
    border-radius: 10px 10px 0 10px;
  }
}
.link {
  text-decoration: underline;
  cursor: pointer;
}
</style>
