<template>
  <div id="lenna-webchat-bnil">
    <launcher></launcher>
  </div>
</template>

<script>
import Launcher from "./components/Launcher";
import Hashids from "hashids";
const hashids = new Hashids("", 6);
export default {
  components: {
    Launcher
  },
  metaInfo() {
    return {
      meta: [
        {
          name: "viewport",
          content:
            "width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0",
          "viewport-fit": "cover"
        }
      ]
    }
  },
  // <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0" viewport-fit=cover/>
  async created() {
    // this.$store.dispatch("chat/getParamQueue", {
    //   key: "queue-max",
    //   type: "max"
    // });
    // this.$store.dispatch("chat/getParamQueue", {
    //   key: "queue-estimate",
    //   type: "estimate"
    // });
    // await this.$store.dispatch("user/checkRegister");
    await this.$store.dispatch("style/setStyle");
    // if (!this.$store.getters["style/useRegisterForm"]) {
    //   await this.$store.dispatch("user/autoRegister");
    // }
    // await this.$store.dispatch("chat/checkQueue");
    // console.log("appId", this.$store.getters.decodedAppId[0]);
    // console.log("user", this.$store.getters["user/user"]);
    this.$socket.on("connect", function() {
      console.log("webchat connected to socket");
    });
    this.$echo
      .channel("ChannelApp." + this.$store.getters.decodedAppId[0])
      .listen("NewUser", data => {
        // console.log("NewUser", data);
        if (data.extra !== null) {
          if (data.extra.apps == "seamless-login") {
            // console.log("extra", data);
            // this.$store.dispatch("user/backendLogin", data);
          }
        } else {
          if (data.user.email == this.$store.getters["user/user"].email) {
            this.$store.dispatch("user/relogin", data);
          }
        }
      })
      .listen("NewMessage", data => {
        // console.log("NewMessage - ", data);
        if (
          data.room.created_by.id == hashids.decode(this.$store.getters["user/user"].id)[0]
        ) {
          
          if(data.user.id !== hashids.decode(this.$store.getters["user/user"].id)[0]) {
            this.$store.dispatch("other/enableScroll", true);
            this.$store.dispatch("other/triggerScroll", true);
            this.$receive.play();
            this.$store.dispatch("chat/newMessage", data);
            this.$store.dispatch("other/triggerScroll", false);
          }

          if(data.room.livechat == null) {
            this.$store.dispatch("other/enableScroll", true);
            this.$store.dispatch("other/triggerScroll", true);
            this.$receive.play();
            this.$store.dispatch("chat/newMessage", data);
            this.$store.dispatch("other/triggerScroll", false);
          }
          
        }
      })
      // !!! ChatResolved Deprecated !!!
      // .listen("ChatResolved", data => {
      //   console.log("ChatResolved", data);
      //   if (data.userId == this.$store.getters["user/user"].id) {
      //     this.$store.dispatch("user/logout", data);
      //   } else {
      //     // console.log("data not same");
      //   }
      // })
      .listen("RequestLive", data => {
        // console.log("RequestLive", data);
        if (data.room.created_by.id == hashids.decode(this.$store.getters["user/user"].id)[0]) {
          const payload = {
            app: data.app,
            livechat: data.liveChat,
            queue: data.queue,
            socket: data.socket,
            user: data.user
          };
          this.$store.dispatch("chat/queueLiveChat", payload);
        }
      })
      .listen("RequestHandled", data => {
        // console.log("RequestHandled", data);
        this.$store.dispatch("chat/queueLiveChat", data);
      })
      .listen("LivechatRequest", data => {
        // console.log("LivechatRequest", data);
        this.$store.dispatch("chat/requestQueueLiveChat", data);

        if(data.room.created_by.id == hashids.decode(this.$store.getters["user/user"].id)[0]) {
          this.$store.dispatch("chat/checkLivechat");
        }

      })
      .listen("LivechatHandle", data => {
        // console.log("LivechatHandle", data);
        this.$store.dispatch("chat/handleQueueLiveChat", data);
        if(data.room.created_by.id == hashids.decode(this.$store.getters["user/user"].id)[0]) {
          this.$store.dispatch("chat/updateQuickButton",[]);
        }
      })
      .listen("LivechatResolved", data => {
        // console.log("LivechatResolved", data);
        // if (data.room.created_by.id == this.$store.getters["user/user"].id) {
        //   this.$store.dispatch("user/logout", data.room);
        // }
      })
      .listen("LivechatAssigned", data => {
        // console.log("LivechatAssigned", data);
        this.$store.dispatch("chat/handleQueueLiveChat", data);
        
        if(data.room.livechat.handle_by !== null) {
          if(data.room.created_by.id == hashids.decode(this.$store.getters["user/user"].id)[0]) {
            this.$store.dispatch("chat/updateQuickButton",[]);
          }
        }
      })
      .listen("MaskingInputText", data => {
        // console.log("MaskingInputText", data);
        if (data.user.id == hashids.decode(this.$store.getters["user/user"].id)[0]) {
          this.$store.dispatch("chat/maskingInput", true);
        }
      })
      .listen("LivechatCancel",data => {
        this.$store.dispatch("chat/handleQueueLiveChat", data);
      });
  }
};
</script>

<style lang="scss">
#lenna-webchat-bnil {
  font-family: $font-family-sans-serif;
}

// font face roboto
@font-face {
  font-family: 'roboto-bold';
  font-style: normal;
  src: url(./assets/fonts/Roboto/roboto.bold.ttf) format("truetype"); 
}

@font-face {
  font-family: 'roboto-medium';
  font-style: normal;
  src: url(./assets/fonts/Roboto/roboto.medium.ttf) format("truetype"); 
}

@font-face {
  font-family: 'roboto-light';
  font-style: normal;
  src: url(./assets/fonts/Roboto/roboto.light.ttf) format("truetype"); 
}

@font-face {
  font-family: 'roboto-regular';
  font-style: normal;
  src: url(./assets/fonts/Roboto/roboto.regular.ttf) format("truetype"); 
}
</style>
