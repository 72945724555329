<template>
  <div
    class="rating"
    :class="{ show: isRating && datas }"
    style="overflow-y: auto;overflow-x: hidden"
  >
    <div class="rating-container lenna-p-4" v-if="datas && !isSuccess">
      <div class="rating-header lenna-p-2 lenna-border-bottom">
        <div class="lenna-d-flex">
          <div>
            <div class="lenna-avatar-sm p-1">
              <div
                class="lenna-avatar-title lenna-bg-primary lenna-text-white lenna-rounded-circle lenna-fs-12"
              >
                {{ acronymText(datas.row.agentname) }}
              </div>
            </div>
          </div>
          <div class="lenna-col-12">
            <div class="lenna-message-head" style="align-self: center;">
              <p style="margin-bottom: 0">{{ datas.row.agentname }}</p>
              <p class="lenna-text-muted lenna-roboto-regular-font">
                {{ datas.row.description ? datas.row.description : "Agent" }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="rating-body lenna-p-3">
        <form @submit.prevent="submitRating()">
          <div class="lenna-d-flex lenna-flex-column lenna-align-items-center">
            <div>
              <h4 class="lenna-text-center">Rate your experience with Agent</h4>
            </div>
            <div class="lenna-mb-3">
              <div
                class="lenna-d-flex lenna-flex-row"
                style="gap: 1.5rem"
                v-if="parseInt(datas.ratingtype) == 3"
              >
                <div v-if="selectedEmot == 1">
                  <img src="@/assets/images/icons/bad.png" width="65" />
                </div>
                <div v-else @click="selecEmot(1)">
                  <img src="@/assets/images/icons/bad-inactive.png" width="65" />
                </div>
                <div v-if="selectedEmot == 2">
                  <img src="@/assets/images/icons/flat.png" width="65" />
                </div>
                <div v-else @click="selecEmot(2)">
                  <img src="@/assets/images/icons/flat-inactive.png" width="65" />
                </div>
                <div v-if="selectedEmot == 3">
                  <img src="@/assets/images/icons/good.png" width="65" />
                </div>
                <div v-else @click="selecEmot(3)">
                  <img src="@/assets/images/icons/good-inactive.png" width="65" />
                </div>
              </div>
              <star-rating
                v-model="form.rate"
                :show-rating="false"
                :max-rating="parseInt(datas.ratingtype)"
                v-else
              ></star-rating>
            </div>
            <div class="lenna-d-flex lenna-justify-content-center">
              <label class="lenna-text-center">{{ description }}</label>
            </div>
          </div>
          <div class="lenna-d-flex lenna-flex-column">
            <div
              class="lenna-mb-2 lenna-col-12 lenna-px-0"
              v-for="(val, idx) in datas.ratingquestion"
              :key="idx"
            >
              <p>{{ val.question }}</p>
              <input
                required
                class="lenna-form-control"
                v-model="form.answer[idx]"
                v-if="val.answer == 'freetext'"
              />
              <select
                required
                class="lenna-form-control"
                v-model="form.answer[idx]"
                v-if="val.answer == 'option'"
              >
                <option v-for="(opt, key) in val.list.split('\r\n')" :key="key">{{
                  opt
                }}</option>
              </select>
            </div>
          </div>
          <div
            class="lenna-d-flex lenna-flex-column lenna-justify-content-left lenna-flex-fill"
            style="min-height: 150px"
          >
            <p>Tell us about experience!</p>
            <textarea
              rows="4"
              class="lenna-form-control lenna-flex-fill"
              v-model="form.comment"
              required
            ></textarea>
          </div>
          <div
            class="lenna-d-flex lenna-justify-content-center lenna-mt-3"
            style="gap: 0.5rem"
          >
            <button
              type="button"
              class="lenna-btn lenna-btn-secondary"
              @click="skipRating"
            >
              Skip
            </button>
            <button
              type="submit"
              class="lenna-btn lenna-btn-primary"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
    <div
      class="rating-container lenna-p-4 lenna-flex-column lenna-d-flex lenna-justify-content-center lenna-align-items-center"
      style="height: 100%;"
      v-else
    >
      <img
        src="@/assets/images/icons/rating-success.svg"
        style="margin-top:30%"
      />
      <h4>Thankyou for your feedback!</h4>
      <button
        type="button"
        class="lenna-btn lenna-btn-primary"
        style="margin-top: auto;width: -webkit-fill-available"
        @click="doneRating"
      >
        Done
      </button>
    </div>
  </div>
</template>
<script>
import StarRating from "vue-star-rating";
import { mapState, mapGetters } from "vuex";
import md5 from "md5";
import Vue from "vue";
export default {
  components: {
    StarRating
  },
  computed: {
    ...mapState("chat", ["isRating", "ratingData"]),
    ...mapGetters({
      style: "style/getStyle"
    }),
    question() {
      if (this.datas && this.datas.ratingquestion.length > 0) {
        return this.datas.ratingquestion.map(v => {
          return v.question;
        });
      } else {
        return [];
      }
    }
  },
  data() {
    return {
      form: {
        rate: 0,
        comment: "",
        question: [],
        answer: []
      },
      description: "Rate us please",
      url: "",
      datas: null,
      isSuccess: false,
      selectedEmot: null,
      emoticon: [
        {
          icon: "@/assets/images/icons/bad.png",
          iconUnactive: "@/assets/images/icons/bad-inactive.png"
        },
        {
          icon: "@/assets/images/icons/flat.png",
          iconUnactive: "@/assets/images/icons/flat-inactive.png"
        },
        {
          icon: "@/assets/images/icons/good.png",
          iconUnactive: "@/assets/images/icons/good-inactive.png"
        }
      ]
    };
  },
  watch: {
    "form.rate"(newVal) {
      let caption = "";
      switch (newVal) {
        case 1:
          caption = "Bad";
          break;
        case 2:
          caption = "Neutral";
          break;
        case 3:
          caption = "Good";
          break;
        case 4:
          caption = "Great";
          break;
        case 5:
          caption = "Excellent";
          break;
      }
      this.description = caption;
    },
    isRating(newVal) {
      if (newVal === true) {
        // let url =
        //   "https://cms.lenna.ai/rating/case_api/ce9f30589a1b9a0bdb62119fdd06808b/2";
        let url = this.ratingData;
        let split = url.split("/");
        this.url = split[5] + "/" + split[6];

        this.initData();
      }
    }
  },
  methods: {
    initData() {
      // https://cms.lenna.ai/rating/case_api/ce9f30589a1b9a0bdb62119fdd06808b/2
      this.$axios
        .get(process.env.VUE_APP_CMS_URL + "/rating/case_api/" + this.url)
        .then(res => {
          console.log("reting", res);
          this.datas = res.data.data;
        });
    },
    submitRating() {
      if (this.form.rate === 0) {
        Vue.notify({
          group: "warning",
          type: "warning",
          title: "Warning",
          text: "Please pick rating first!"
        });
        return;
      }
      // console.log("submit", this.form);
      let formData = new FormData();
      formData.append("ticketid", md5(this.datas.row.id));
      formData.append("type", this.datas.row.type);
      formData.append("comment", this.form.comment);
      formData.append("rate", this.form.rate);
      // this.form.ticketid = md5(this.datas.row.id);
      // this.form.type = this.datas.row.type;
      if (this.datas.ratingquestion.length > 0) {
        this.form.question = this.question;
        for (let i = 0; i < this.form.question.length; i++) {
          formData.append("question[" + i + "]", this.form.question[i]);
          formData.append("answer[" + i + "]", this.form.answer[i]);
        }
      }
      console.log("formdata", formData);
      // return;
      this.$axios
        .post(process.env.VUE_APP_CMS_URL + "/rating/save", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(res => {
          console.log("submit", res);
          // this.$store.commit("chat/UPDATE_IS_RATING", false);
          // this.datas = null;
          this.resetForm();
          this.isSuccess = true;
          this.$store.commit("chat/UPDATE_RATING_DATA", null);
        });
    },
    skipRating() {
      this.datas = null;
      this.$store.commit("chat/UPDATE_IS_RATING", false);
      this.$store.commit("chat/UPDATE_RATING_DATA", null);
      this.resetForm();
    },
    doneRating() {
      this.datas = null;
      this.isSuccess = false;
      this.$store.commit("chat/UPDATE_IS_RATING", false);
      this.$store.commit("chat/UPDATE_RATING_DATA", null);
      this.resetForm();
    },
    resetForm() {
      this.form.rate = 0;
      this.form.comment = "";
      this.form.question = [];
      this.form.answer = [];
      this.description = "Rate us please";
    },
    selecEmot(val) {
      this.form.rate = val;
      this.selectedEmot = val;
    }
  }
};
</script>
<style lang="scss">
.rating {
  height: 0;
  transition: height 0.25s;
}
.rating.show {
  position: absolute;
  width: 100%;
  height: 80%;
  bottom: 0;
  z-index: inherit;
  transition: height 0.5s cubic-bezier(0.17, 0.04, 0.03, 0.94);
  background: #fafdff;
  border-radius: 20px 20px 0px 0px;
  // .rating-container {
  //   max-height: 80%;
  // }
}
</style>
