<template>
  <div class="lenna-register-main lenna-card-body">
    <notifications
      group="error"
      classes="vue-notification shadow-sm"
      position="top center"
      :reverse="true"
    />
    <div class="lenna-form-group">
      <label class="lenna-text-muted">
        Selamat datang di
        <b>BNI Life Chat.</b>
        Silakan mengisi formulir di bawah untuk berbicara dengan Customer Care
        Officer kami.
      </label>
    </div>
    <div
      class="lenna-d-flex lenna-justify-content-center"
      v-if="registerLoading"
    >
      <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <form
      class="lenna-form-lenna"
      @submit.prevent="submitHandler"
      style="display: inline;"
    >
      <div class="lenna-form-group">
        <input
          type="text"
          :placeholder="style.app && style.app.id == 537 ? 'Name' : 'Nama'"
          v-model="form.name"
          name="name"
          v-validate="'required'"
          style="font-family: 'roboto-regular' !important"
        />
        <small class="lenna-text-danger">{{ errors.first("name") }}</small>
      </div>
      <div class="lenna-form-group">
        <input
          type="email"
          placeholder="Email"
          v-validate="'required|email'"
          v-model="form.email"
          name="email"
          style="font-family: 'roboto-regular' !important"
        />
        <small class="lenna-text-danger">{{ errors.first("email") }}</small>
      </div>

      <div class="lenna-form-group">
        <input
          type="tel"
          :placeholder="style.app && style.app.id == 537 ? 'Phone' : 'Telepon'"
          v-model="form.phone"
          name="phone"
          v-validate="'required|numeric|min:9'"
          style="font-family: 'roboto-regular' !important"
        />
        <small class="lenna-text-danger">
          {{ errors.has("phone") ? "Please enter a valid phone number" : "" }}
        </small>
      </div>
    </form>
    <button
      type="submit"
      :disabled="disable"
      @click.prevent="submitHandler"
      class="lenna-btn lenna-btn-block"
      :style="{
        color: style.text_self,
        'background-color': '#ff7429',
        'border-radius': '4px',
        'text-transform': 'uppercase'
      }"
    >
      {{ style.app && style.app.id == 537 ? "Start Chat" : "Mulai Chat" }}
    </button>
    <!-- <lenna-credit /> -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import "vue-select/dist/vue-select.css";

// import LennaCredit from "@/components/window/LennaCredit";
export default {
  data() {
    return {
      form: {
        name: null,
        email: null,
        phone: null,
        fields: []
      },
      disable: false
    };
  },
  computed: {
    ...mapGetters({
      style: "style/getStyle",
      registerLoading: "other/registerLoading",
      useRegisterForm: "style/useRegisterForm"
    }),
    lennaInit() {
      const data = JSON.parse(localStorage.getItem("lenna_initialize_bnil"));

      return data;
    }
  },
  methods: {
    async submitHandler() {
      let validated = await this.$validator.validateAll();

      if (validated) {
        this.disable = true;
        await this.$store.dispatch("chat/isFirst", true);
        await this.$store.dispatch("user/register", { ...this.form });
        // await this.$store.dispatch("chat/checkQueue");
        this.form.name = this.form.email = this.form.phone = null;
        this.disable = false;
      }
    }
  },
  watch: {
    style() {
      this.form.fields = this.style.fields;
    },
    async useRegisterForm() {
      console.log("use register", this.useRegisterForm);
      if (this.useRegisterForm != undefined && !this.useRegisterForm) {
        this.$store.commit("other/SET_REGISTER_LOADING", true);
        if (this.$store.getters.userToken) {
          await this.$store.dispatch(
            "user/autoRegister",
            this.$store.getters.userToken,
            {
              root: true
            }
          );
        } else {
          console.log("masuk sini");
          this.$store.dispatch("user/autoRegister", {}, { root: true });
        }
        this.$store.commit("other/SET_REGISTER_LOADING", true);
      }
    }
  },
  mounted() {}
};
</script>
<style lang="scss" scoped>
.lenna-register-main {
  height: 37.5vh;
  overflow-y: auto;

  background: #ffffff !important;
}
.lenna-form-lenna {
  overflow-y: scroll;
  height: 36%;
  margin-bottom: 10px;
}
.lds-ring {
  display: inline-block;
  position: absolute;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #828a90;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #828a90 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
form {
  div {
    input {
      background-color: white !important;
      border: 1px solid #e5e5e5 !important;
      padding: 8px;
      width: 100%;
      outline: none;
      border-radius: 4px;
    }
  }
}
</style>
