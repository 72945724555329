var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lenna-message",class:{
    'lenna-self': _vm.isSelf,
    'lenna-others': !_vm.isSelf,
    'lenna-others-block': !_vm.isSelf,
    'lenna-d-flex': !_vm.checkIsObject(_vm.content.text)
  },style:({ display: _vm.checkIsObject(_vm.content.text) ? 'none' : '' })},[_c('div',{class:{
      'lenna-d-flex lenna-custom-header': !_vm.isSelf
    }},[_c('message-avatar',{attrs:{"isSelf":_vm.isSelf,"message":_vm.message}}),_c('message-head',{attrs:{"isSelf":_vm.isSelf,"message":_vm.message}})],1),_c('div',{staticClass:"lenna-message-container"},[_c('div',{staticClass:"lenna-message-content",class:{
        handleClassAnimate: _vm.handleClassAnimate
      },style:({
        'background-color': _vm.isSelf ? 'transparent' : '#ff7429',
        border: _vm.isSelf ? '2px solid #cf8963' : 'none',
        'border-radius': _vm.isSelf
          ? '24px 24px 0px 24px !important'
          : '24px 24px 24px 0 !important'
      }),attrs:{"id":_vm.handleId}},[_c('div',{staticClass:"lenna-text-content",style:({
          color: _vm.isSelf ? 'black' : 'white'
        })},[_c('span',{staticStyle:{"white-space":"pre-line","word-break":"break-word"}},[_vm._v("\n          "+_vm._s(this.content.text)+"\n        ")])])]),_c('message-footer',{attrs:{"isSelf":_vm.isSelf,"message":_vm.message}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }