var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"chatBody",staticClass:"lenna-chat-body lenna-card-body",style:({
    background:
      _vm.style.background_type == 'image'
        ? `url(${_vm.style.background_value})`
        : _vm.style.background_value + '!important'
  }),attrs:{"id":"chatBody"}},[_c('notifications',{attrs:{"group":"error","classes":"vue-notification shadow-sm","position":"top center"}}),(_vm.$store.getters['other/trigger'] || _vm.$store.getters['user/isLogedIn'])?_c('infinite-loading',{staticClass:"lenna-mx-auto lenna-my-2",attrs:{"direction":"top"},on:{"infinite":_vm.infiniteHandler}},[_c('div',{attrs:{"slot":"no-more"},slot:"no-more"},[_c('span',{staticClass:"lenna-badge lenna-badge-primary lenna-px-2 lenna-py-1",style:({
          backgroundColor: _vm.style.bubble_self,
          whiteSpace: 'inherit',
          maxWidth: '225px'
        })},[_vm._v("\n        "+_vm._s(_vm.appId == "386"
            ? "Selamat datang di Layanan Chat Us BNICall, CINTA siap menjawab pertanyaan kamu"
            : "This is the first message")+"\n      ")])]),_c('div',{attrs:{"slot":"no-results"},slot:"no-results"},[_c('span',{staticClass:"lenna-badge lenna-badge-primary lenna-px-2 lenna-py-1",style:({
          backgroundColor: '#006786',
          whiteSpace: 'inherit',
          maxWidth: '225px'
        })},[_vm._v("\n        "+_vm._s(_vm.appId == "386"
            ? "Selamat datang di Layanan Chat Us BNICall, CINTA siap menjawab pertanyaan kamu"
            : "You don't have any message")+"\n      ")])])]):_vm._e(),_c('div',{staticClass:"lenna-d-flex lenna-flex-column"},[_c('transition-group',{attrs:{"name":"fade"}},_vm._l((_vm.messageList),function(message,x){return _c('div',{key:x,staticStyle:{"animation-duration":"0.5s"}},[_vm._l((message.content),function(content,index){return [[(
                _vm.checkIsSelf(message) ? (content.show = true) : content.show
              )?_c('type-' + content.type,{key:`${index}.${_vm.random(0, 99999999999999)}`,tag:"component",attrs:{"content":content,"index":x,"isSelf":_vm.checkIsSelf(message),"message":message,"id":x,"type":message.messageable_type,"total":_vm.messageList.length}}):_vm._e()]]})],2)}),0),(_vm.isTyping)?_c('type-typing',{tag:"component",attrs:{"isSelf":false}}):_vm._e(),_c('div',[_c('type-quickbutton' || 'type-quickButtons',{key:_vm._.now(),tag:"component",attrs:{"isSelf":true}})],1),(_vm.queue !== null ? _vm.queue.state : false)?_c('type-queue',{tag:"component"}):_vm._e()],1),_c('div',{staticClass:"bottom-scroll lenna-pb-1",attrs:{"id":"bottom-scroll"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }