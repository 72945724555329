<template>
  <div
    class="lenna-chat-body lenna-card-body"
    ref="chatBody"
    id="chatBody"
    :style="{
      background:
        style.background_type == 'image'
          ? `url(${style.background_value})`
          : style.background_value + '!important'
    }"
  >
    <notifications
      group="error"
      classes="vue-notification shadow-sm"
      position="top center"
    />
    <infinite-loading
      v-if="$store.getters['other/trigger'] || $store.getters['user/isLogedIn']"
      class="lenna-mx-auto lenna-my-2"
      direction="top"
      @infinite="infiniteHandler"
    >
      <div slot="no-more">
        <span
          class="lenna-badge lenna-badge-primary lenna-px-2 lenna-py-1"
          :style="{
            backgroundColor: style.bubble_self,
            whiteSpace: 'inherit',
            maxWidth: '225px'
          }"
        >
          {{
            appId == "386"
              ? "Selamat datang di Layanan Chat Us BNICall, CINTA siap menjawab pertanyaan kamu"
              : "This is the first message"
          }}
        </span>
      </div>
      <div slot="no-results">
        <span
          class="lenna-badge lenna-badge-primary lenna-px-2 lenna-py-1"
          :style="{
            backgroundColor: '#006786',
            whiteSpace: 'inherit',
            maxWidth: '225px'
          }"
        >
          {{
            appId == "386"
              ? "Selamat datang di Layanan Chat Us BNICall, CINTA siap menjawab pertanyaan kamu"
              : "You don't have any message"
          }}
        </span>
      </div>
    </infinite-loading>
    <!-- <button @click="scroll()" class="lenna-btn lenna-btn-danger">x</button> -->
    <div class="lenna-d-flex lenna-flex-column">
      <transition-group name="fade">
        <div
          v-for="(message, x) in messageList"
          :key="x"
          style="animation-duration: 0.5s"
        >
          <template v-for="(content, index) in message.content">
            <template>
              <component
                v-if="
                  checkIsSelf(message) ? (content.show = true) : content.show
                "
                :is="'type-' + content.type"
                :content="content"
                :index="x"
                :key="`${index}.${random(0, 99999999999999)}`"
                :isSelf="checkIsSelf(message)"
                :message="message"
                :id="x"
                :type="message.messageable_type"
                :total="messageList.length"
              ></component>
            </template>
          </template>
        </div>
        <!-- <template v-if="contentImage.length > 0">
          <component
            :is="'type-image'"
            :key="`${random(0, 99999999999999)}`"
            :contentImage="contentImage"
          ></component>
        </template> -->
      </transition-group>
      <!-- <component :is="'type-countdown'" v-if="isCounting"></component> -->
      <!-- <component :is="'type-confirm'" :isSelf="false"></component> -->
      <component
        :is="'type-typing'"
        :isSelf="false"
        v-if="isTyping"
      ></component>
      <div>
        <!-- <transition-group name="fadeInUp"> -->
        <component
          :is="'type-quickbutton' || 'type-quickButtons'"
          :key="_.now()"
          :isSelf="true"
        ></component>
        <!-- </transition-group> -->
      </div>
      <component
        :is="'type-queue'"
        v-if="queue !== null ? queue.state : false"
      ></component>
    </div>
    <div id="bottom-scroll" class="bottom-scroll lenna-pb-1"></div>
  </div>
</template>
<script>
import Text from "./message/messageTypes/Text";
import Html from "./message/messageTypes/Html";
import Carousel from "./message/messageTypes/Carousel";
import Image from "./message/messageTypes/Image";
import Video from "./message/messageTypes/Video";
import Typing from "./message/messageTypes/Typing";
import Quickbutton from "./message/messageTypes/Quickbutton";
import Form from "./message/messageTypes/Form";
import Confirm from "./message/messageTypes/Confirm";
import Summary from "./message/messageTypes/Summary";
import Countdown from "./message/messageTypes/Countdown";
import Template from "./message/messageTypes/Template";
import File from "./message/messageTypes/File";
import Base from "./message/messageTypes/Base";
import Queue from "./message/messageTypes/Queue";
import random from "lodash/random";
import { size, last, drop } from "lodash";
import { mapGetters } from "vuex";
import Hashids from "hashids";
const hashids = new Hashids("", 6);
// import LennaCredit from "../window/CreditBottomChat";
// import moment from "moment";
// SAMPAI DI CLASS BOTTOM scroll
// DAN SCROLLTO
export default {
  components: {
    "type-text": Text,
    "type-image": Image,
    "type-carousel": Carousel,
    "type-typing": Typing,
    "type-form": Form,
    "type-confirm": Confirm,
    "type-button": Confirm,
    "type-summary": Summary,
    "type-countdown": Countdown,
    "type-html": Html,
    "type-template": Template,
    "type-file": File,
    "type-loginForm": Form,
    "type-registerFormGBK": Form,
    "type-quickbutton": Quickbutton,
    "type-quickButtons": Base,
    "type-queue": Queue,
    "type-video": Video
    // "lenna-credit": LennaCredit
  },
  data() {
    return {
      nextPage: 1,
      helpers: [{ id: "1" }],
      allowScrollInfinite: true
    };
  },
  computed: {
    ...mapGetters({
      messageList: "chat/messageList",
      isTyping: "chat/isTyping",
      // isCounting: "chat/isCounting",
      style: "style/getStyle",
      enableScroll: "other/enableScroll",
      triggerScroll: "other/triggerScroll",
      firstMessageId: "chat/firstIdMessage",
      page: "chat/page",
      queue: "chat/getQueueData",
      contentImage: "chat/contentImage"
    }),
    appId() {
      return this.$store.getters.decodedAppId[0];
    }
  },
  watch: {
    messageList: function(newVal, oldVal) {
      let lastNew = last(newVal);
      if (!lastNew.content) {
        lastNew = lastNew.original.data.message;
      }
      if (size(oldVal) == 0) {
        for (let i = 0; i < size(newVal); i++) {
          for (let x = 0; x < size(newVal[i].content); x++) {
            newVal[i].content[x].show = true;
          }
        }
        this.$store.dispatch("other/disableInput", false);
        this.$store.dispatch("other/enableScroll", true);
        return this.$store.dispatch("other/triggerScroll", true);
        // this.scroll("tes 4");
      }
      if (lastNew.messageable_type !== "user") {
        // this.scroll();
        if (size(lastNew.content) <= 1) {
          lastNew.content[0].show = true;
          // this.scroll();
          return this.$store.dispatch("other/triggerScroll", true);
        }
        for (let x = 0; x < size(lastNew.content); x++) {
          setTimeout(() => {
            lastNew.content[x].show = true;
            this.$store.dispatch("chat/updateTyping", true);
            // this.scrollToBottom();
            if (x == size(lastNew.content) - 1) {
              // this.scrollToBottom();
              this.$store.dispatch("chat/updateTyping", false);
              // this.scrollToBottom("tes 1");
              // return this.$store.dispatch("other/triggerScroll", true);
            }
          }, x * 1000);
        }
        return this.$store.dispatch("other/triggerScroll", true);
        // this.scroll("tes 2");
      } else {
        // return this.$store.dispatch("other/triggerScroll", true);
        // this.scroll("tes 5");
      }
      // this.$store.dispatch("other/triggerScroll", true);
      // this.scroll("tes 3");
    },
    isTyping: function() {
      if (this.enableScroll == true) {
        // this.scrollToBottom("from is typing");
        this.scroll("from is typing");
      }
    },
    triggerScroll: function(newVal) {
      if (newVal == true) {
        this.scroll("from trigger scroll");
        // this.scrollToBottom("from trigger scroll");
      }
    }
  },
  methods: {
    async infiniteHandler($state) {
      this.$store.dispatch("other/disableInput", true);
      this.$store.dispatch("other/enableScroll", false);
      this.allowScrollInfinite = false;
      try {
        let response = await this.$axios.post(
          `/${this.$store.getters.appId.hashed}/message/get-messages`,
          {
            id: this.$store.getters["user/user"].id
          },
          {
            params: {
              firstMessageId: this.firstMessageId
            }
          }
        );
        const res = response.data;
        // console.log("infinite res", res.data);
        if (res.data == null || res.data.length == 0) {
          this.$store.dispatch("other/disableInput", false);
          $state.complete();
          this.allowScrollInfinite = true;
          return;
        }
        this.$store.dispatch("other/disableInput", false);
        this.$store.dispatch("other/enableScroll", false);
        if (res.data.length !== 0) {
          await this.$store.dispatch(
            "chat/updateFirstMessageId",
            res.data[0].id
          );
          await this.$store.dispatch("chat/addMoreMessage", res.data);
          $state.loaded();
          this.allowScrollInfinite = true;
        }
      } catch (error) {
        console.log("infinite error", error);
        this.$store.dispatch("other/disableInput", false);
        $state.complete();
        this.allowScrollInfinite = true;
      }
    },
    checkIsSelf(message) {
      // let userId = "";
      // if (isNaN(this.$store.getters["user/user"].id)) {
      const userId = hashids.decode(this.$store.getters["user/user"].id);
      // } else {
      // userId = this.$store.getters["user/user"].id;
      // }
      return (
        message.messageable_id == userId[0] &&
        message.messageable_type == "user"
      );
    },
    random(from, to) {
      return random(from, to);
    },
    scrollToBottom() {
      // console.log("enableScroll", this.enableScroll);
      // console.log("scrollToBottom", data);
      if (this.enableScroll == true) {
        // setTimeout(() => {
        // let container = this.$el.querySelector("#chatBody");
        // container.scrollTop = container.scrollHeight;
        //
        const el = this.$el.getElementsByClassName("bottom-scroll")[0];
        if (el) {
          // Use el.scrollIntoView() to instantly scroll to the element
          el.scrollIntoView({ behavior: "smooth" });
        }
        //
        // this.$refs.chatBody.scrollTop = this.$refs.chatBody.scrollHeight;
        // }, 10);
      }
    },
    scroll() {
      // console.log("enableScroll", this.enableScroll);
      // console.log("scroll", data);
      if (this.enableScroll == true) {
        if (this.allowScrollInfinite) {
          return this.$scrollTo("#bottom-scroll", 1500, {
            offset: 0,
            container: "#chatBody",
            force: true,
            lazy: true,
            onDone: this.onDone,
            onStart: this.onStart
          });
        }
      }
    },
    onDone() {
      this.$store.dispatch("other/enableScroll", true);
      this.$store.dispatch("other/triggerScroll", true);
      // console.log("onDone", e);
    },
    onStart() {
      this.$store.dispatch("other/triggerScroll", false);
      this.$store.dispatch("other/enableScroll", false);
      // console.log("onStart", e);
    }
  },
  created() {
    if (!this.$store.getters["chat/isFirst"]) {
      drop(this.messageList);
    }
  },
  mounted() {
    // console.log('message list', this.messageList);
    if (
      this.$store.getters["user/isLogedIn"] &&
      this.$store.getters["user/location"] !== null
    ) {
      if (this.$store.getters["other/isEnabledLocation"]) {
        this.$store.dispatch("user/updateLocation");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#chatBody {
  overflow-anchor: none;
}
#bottom-scroll {
  /* allow the final child to be selected as an anchor node */
  overflow-anchor: auto;

  /* anchor nodes are required to have non-zero area */
  height: 1px;
}

.lenna-chat-body {
  height: 55vh;
  overflow-y: scroll;
  overflow-x: hidden;
  // background: red !important;
  padding: 0 !important;
}
.bounceIn-enter,
.bounceIn-leave-to {
  opacity: 0;
  transition: transform 0.2s;
  transform: scale(0.3);
}
.bounceIn-enter-to,
.bounceIn-leave {
  opacity: 1;
  transition: transform 0.2s;
  transform: scale(1);
}
.bounceIn-enter-active,
.bounceIn-leave-active {
  transition: all 200ms ease-in-out;
}
</style>
